// export const BaseUrl = 'https://twinscart.com:8000'

export const BaseUrl = 'https://twinscart.com:8000'

// export const BaseUrl = 'http://192.168.29.200:8000'



export const token = localStorage.getItem("token");



