import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";


import { BaseUrl, token } from "../utils/Token";
import { Col, Container, Row, Table } from "react-bootstrap";
import adminLayout from "../hoc/adminLayout";
import Loader from "../common/Loader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

// it is category List And this View Product

function Ticket() {
    const [user, setUser] = useState([]);


    const [checked, setChecked] = useState(false);

    const [open, setopen] = useState(false)

    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const navigate = useNavigate()

    const fetchData = async () => {
        setopen(true)
        try {
            const response = await axios.get(
                `${BaseUrl}/tickets`,
                {
                    headers,
                }
            );
            setUser(response?.data?.data);
            console.log(response.data.data)
            if (response.data.data) {
                setopen(false)
            }
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };


    useEffect(() => {
        // Fetch data initially
        fetchData();

        // Set up an interval to fetch data every 1 minute (60000 ms)
        const intervalId = setInterval(fetchData, 60000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const getRowId = (row) => row._id;
    const handleEditClick = (id) => {

    };

    const columns = [
        {
            field: "User Phone",
            headerName: "from_user",
            flex: 1,
            renderCell: (params) => {
                return <span>{params.row.from_user.phone}</span>
            }
        },
        {
            field: "issue",
            headerName: "issue",
            flex: 1,
        },
        {
            field: "priority",
            headerName: "Priority",
            flex: 1,
        },
        {
            field: "status",
            headerName: "status",
            flex: 1,
        },
        {
            field: "chat",
            headerName: "Chat",
            flex: 1,
            renderCell: (params) => {

                return params.row.is_chat_open ? <a href={`/chat/${params.row._id}`} className="btn bg-primary text-white" >Chat</a> : <span className="badge bg-secondary" onClick={(e) => toast.error("Chat is not available at this time.")}>
                    No Chat Available
                </span>

            }
        },
        {
            field: "Chat Status",
            headerName: "Chat Status",
            flex: 1,
            renderCell: (params) => {

                return params.row.is_chat_open ? (
                    <button
                        onClick={(e) => handlechatclose(e, params.row._id)}
                        className="btn bg-success text-white"
                    >
                        End Chat
                    </button>
                ) : (
                    <span className="badge bg-secondary" onClick={(e) => toast.error("User is Offline")}>
                        Offline
                    </span>
                );

            }
        },
        {
            field: "ticket close",
            headerName: "Ticket Status",
            flex: 1,
            renderCell: (params) => {
                return params.row.status === "Pending" ? (
                    <button
                        onClick={(e) => handleclosed(e, params.row._id)}
                        className="btn bg-primary text-white"
                    >
                        Close Ticket
                    </button>
                ) : (
                    <span className="badge bg-secondary" onClick={(e) => toast.error("Ticket is already is Closed")}>
                        Ticket is Closed
                    </span>
                );

            }
        },
        {
            field: "created",
            headerName: "created_at",
            flex: 1,
            renderCell: (params) => {

                return <span>{moment(params.row.created_at).format("MMMM DD, YYYY")}</span>


            }
        }

    ];


    const handleclosed = (e, id) => {
        e.preventDefault();
        setopen(true)
        let requestdata = {
            status: "Closed",
            _id: id
        }
        axios.put(`${BaseUrl}/tickets`, requestdata, {
            headers,
        }).then((res) => {
            console.log(res.data.status)
            if (res.data.status == "OK") {
                setopen(false)
                fetchData()
            }
        })
    }

    const handlechatclose = (e, id) => {

        e.preventDefault();
        setopen(true)
        let requestdata = {
            is_chat_open: false,
            _id: id
        }
        axios.put(`${BaseUrl}/tickets`, requestdata, {
            headers,
        }).then((res) => {
            console.log(res)
            if (res.data.status == "OK") {
                setopen(false)
                fetchData()
            }

        })
    }


    const [isSmallScreen, setIsSmallScreen] = useState(false)
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        // Initialize the state based on the initial window size
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [searchInput, setSearchInput] = useState("");

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value);
    };

    const filteredTickets = user.filter(
        (item) =>
            item.status.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.priority.toLowerCase().includes(searchInput.toLowerCase()) || item.from_user.phone.includes(searchInput)
    );

    return (
        <>
            <Loader open={open} />
            <section>
                <Container>
                    <Row>
                        {isSmallScreen && <div className="col-md-12">
                            <div className="input-group bg-white shadow rounded-3 mb-3">
                                <input
                                    type="search"
                                    className="form-control border-0 rounded-3" style={{ paddingLeft: "30px" }}
                                    placeholder="Search by Status, Priority, or Phone"

                                    value={searchInput}
                                    onChange={handleSearchInput}
                                />
                                <span className="" style={{ position: "absolute", left: "10px", top: "6px", zIndex: 100 }}>
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </span>
                            </div>
                        </div>}
                        <Col md="12">
                            <div className={`${isSmallScreen ? "" : "bg-white  box"}`}>
                                <h4 className={`${isSmallScreen ? "mt-2" : "px-3 py-3"}`}>Ticket List</h4>
                                {isSmallScreen ?

                                    <>

                                        <div className="tablefont table-responsive">

                                            {
                                                filteredTickets.length > 0 ? filteredTickets.map((item) => {
                                                    return (
                                                        <div key={item._id} className="bg-white shadow rounded-3 p-3 my-3">
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <h6 className="mb-0">
                                                                    {item.from_user.phone}
                                                                </h6>
                                                                <div className="d-flex align-items-center gap-2">
                                                                    <span className={`badge ${item.priority == 'High' ? 'bg-danger' : item.priority === 'Medium' ? 'bg-warning' : 'bg-success'}`}>
                                                                        {item.priority}
                                                                    </span>
                                                                    <span className={`badge ${item.status == 'Pending' ? 'bg-info' : item.status == 'Closed' ? 'bg-secondary' : 'bg-success'}`}>
                                                                        {item.status}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <h5 className="mt-2">{item.issue}</h5>
                                                            <div className="d-flex align-items-center gap-3 mt-3">
                                                                <div>
                                                                    {item.is_chat_open ? (
                                                                        <a href={`/chat/${item._id}`} className="btn bg-primary text-white">Chat</a>
                                                                    ) : (
                                                                        <span className="badge bg-secondary" onClick={() => toast.error("Chat is not available at this time.")}>
                                                                            No Chat Available
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {item.is_chat_open ? (
                                                                        <button onClick={(e) => handlechatclose(e, item._id)} className="btn bg-success text-white">End Chat</button>
                                                                    ) : (
                                                                        <span className="badge bg-secondary" onClick={() => toast.error("User is Offline")}>
                                                                            Offline
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div>
                                                                    {item.status === "Pending" ? (
                                                                        <button onClick={(e) => handleclosed(e, item._id)} className="btn bg-primary text-white">Close Ticket</button>
                                                                    ) : (
                                                                        <span className="badge bg-secondary" onClick={() => toast.error("Ticket is already Closed")}>
                                                                            Ticket is Closed
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="mt-3">
                                                                <h6 className="text-secondary">{moment(item.created_at).format("MMMM DD, YYYY")}</h6>
                                                            </div>
                                                        </div>
                                                    );
                                                }) : <>
                                                    <div className="text-center my-4">
                                                        <h6 className="fw-bold">Ticket is Not Available</h6>
                                                    </div>
                                                </>}


                                        </div>
                                    </>
                                    :
                                    <div className="">
                                        <DataGrid
                                            rows={user}
                                            columns={columns}
                                            getRowId={getRowId}

                                            pageSizeOptions={[5, 10, 25]}
                                        />
                                    </div>}
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    );
}

export default adminLayout(Ticket);
