




import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Message from './Message';
import "./chat.css";
import adminLayout from '../hoc/adminLayout';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import axios from 'axios';
import { BaseUrl } from '../utils/Token';
import { Link, useParams } from 'react-router-dom';

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const { roomid } = useParams();
    const [input, setInput] = useState('');
    const chatContainerRef = useRef(null);
    const clientRef = useRef(null);

    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("userid");

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fileMessage = "text";

    const handleGet = () => {
        axios.get(`${BaseUrl}/single_chat?room_id=${roomid}`, { headers })
            .then((res) => {
                setMessages(res.data.data?.reverse());
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    };

    useEffect(() => {
        handleGet();
    }, [roomid]);

    useEffect(() => {
        // Initialize WebSocket connection
        clientRef.current = new W3CWebSocket(`wss://twinscart.com:8001`);

        clientRef.current.onopen = () => {
            console.log('WebSocket Client Connected');
            clientRef.current.send(
                JSON.stringify({
                    type: 'joinRoom',
                    room: roomid,
                })
            );
        };

        clientRef.current.onmessage = (message) => {
            const data = JSON.parse(message.data);
            setMessages((prevMessages) => [...prevMessages, data.data]);
        };

        clientRef.current.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        return () => {
            if (clientRef.current) {
                clientRef.current.close();
            }
        };
    }, [roomid]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const sendMessage = async (e) => {
        e.preventDefault();
        if (input.trim()) {
            const msg = {
                room_id: roomid,
                sender: userid,
                message: input,
                file_message: fileMessage
            };
            try {
                await axios.post(`${BaseUrl}/single_chat`, msg, { headers });
                setInput('');
                // Send the message to the WebSocket server
                clientRef.current.send(JSON.stringify(msg));
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };


    const [isSmallScreen, setIsSmallScreen] = useState(false)
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        // Initialize the state based on the initial window size
        handleResize();

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (

        <section>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <a href={'/ticket'} className='btn mb-3 bg-primary text-white'><i class="fa-solid fa-chevron-left"></i> Ticket List</a>
                    </div>
                    <div className="col-md-6">
                        <Card className="flex-grow-1">
                            <Card.Header className="text-center">
                                <h2>Chat App</h2>
                            </Card.Header>
                            <Card.Body className="d-flex flex-column">
                                <div className="flex-grow-1 overflow-auto mb-3 chatcontent" ref={chatContainerRef}>
                                    {messages.map((msg, index) => (
                                        <Message
                                            key={index}
                                            msg={msg.message}
                                            type={msg.sender.type}
                                            sender={userid}
                                            reciver={msg?.sender?._id}
                                        />
                                    ))}
                                </div>
                                <Form onSubmit={sendMessage} className="d-flex">
                                    <Form.Control
                                        type="text"
                                        placeholder="Type a message..."
                                        value={input}
                                        onChange={(e) => setInput(e.target.value)}
                                        className="me-2"
                                    />
                                    <button type="submit" className="bg-primary btn text-white">Send</button>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default adminLayout(Chat);
