import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";


import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";
import Loader from "../../common/Loader";
import moment from "moment/moment";



function Contact() {

    const [user, setUser] = useState([]);
    const [open, setopen] = useState(false)

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${BaseUrl}/fetch_contact_form_data`,
                {
                    headers,
                }
            );
            setUser(response?.data?.data?.reverse());
            console.log(response.data?.data)
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);

    const getRowId = (row) => row._id;





    const columns = [

        { field: "name", headerName: "Name", flex: 1 },
        { field: "email", headerName: "Email", flex: 1 },
        { field: "phone", headerName: "Phone", flex: 1 },
        { field: "subject", headerName: "Subject", flex: 1 },
        { field: "type", headerName: "Type", flex: 1 },

        {
            field: "created_at",
            headerName: "Create Date",
            flex: 1,
            renderCell: (params) => {
                return moment(params.row.created_at).format("D MMMM YYYY");
            }
        }











    ];



    // const reversedRows = [...user].reverse();
    return (
        <>
            <Loader open={open} />
            <section>
                <Container>
                    <Row>


                        {/* <div className="col-md-12">
                            <div className="text-end mb-3">
                                <Link className="btn bg-primary text-white">IT Contact</Link>
                            </div>
                        </div> */}
                        <Col md="12">
                            <div className="bg-white  box">
                                <h4 className="px-3 py-3">Contact Queries</h4>
                                {/* {user.map((item) => {
                                    return (
                                        <>
                                            <h5>{item.name}</h5>
                                        </>
                                    )
                                })} */}
                                <div className="">
                                    <DataGrid
                                        rows={user}
                                        columns={columns}
                                        getRowId={getRowId}
                                        initialState={{
                                            ...user.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default adminLayout(Contact);
