import React from 'react';
import { Alert } from 'react-bootstrap';

const Message = ({ msg, sender, reciver, type }) => {
    return (

        <div className={`d-flex ${(sender == reciver || type == "Admin" || type == "Staff") ? 'justify-content-end' : 'justify-content-start'} mb-2`}>

            {msg && <Alert

                variant={(sender == reciver || type == "Admin" || type == "Staff") ? 'success' : 'primary'}
                className="p-2 rounded-lg mb-0"
                style={{ maxWidth: '250px', wordWrap: 'break-word' }}
            >
                <p className="mb-0">{msg} </p>
            </Alert>}
        </div>
    );
};

export default Message;
