import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";
import { DeleteForever } from "@mui/icons-material";

function BlogsList() {
    const [section, setsection] = useState([]);

    const navigate = useNavigate();

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BaseUrl}/blogs`, {
                headers,
            });
            setsection(response.data.data);
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handledeleteClick = async (id) => {
        let data = JSON.stringify({
            _id: id,
            deleted_at: "D",
        });

        let config = {
            method: "delete",
            maxBodyLength: Infinity,
            url: `${BaseUrl}/blogs`,
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.status == "OK") {
                    // try {
                    //   const res = axios.get("https://twinscart.com:8000/section", {
                    //     headers,
                    //   });

                    //   setsection(res.data.data);
                    // } catch (error) {
                    //   console.error("Error fetching category data:", error);
                    // }
                    window.location.reload(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getRowId = (row) => row._id;
    const handleEditClick = (id, index) => {
        // Now, 'index' contains the index of the "Edit" button that was clicked.
        // You can use it as needed.
        console.log("Edit button clicked for item with ID:", id);
        console.log("Index of clicked Edit button:", index);
        // Add your edit logic here
    };

    const columns = [
        // { field: "_id", headerName: "ID", flex:1},
        { field: "heading", headerName: "Heading", flex: 1 },

        {
            field: "image",
            headerName: "image",
            width: 300,
            renderCell: (params) => {
                console.log(params.row)
                let image1 = params.row.images[0]
                return (
                    <>
                        <img
                            src={`${BaseUrl}/${image1}`}
                            className="img-fluid tableimgmui"
                        ></img>
                    </>

                );
            },
        },
        {
            field: "edit",
            headerName: "Edit",

            width: 300,
            renderCell: (params) => {
                const id = params.row._id;
                const index = section.findIndex((item) => item._id === id);

                return (
                    <Link to={`/editblogs/${id}`}>
                        <IconButton
                            style={{ color: "black" }}
                            aria-label="edit"
                            onClick={() => handleEditClick(id, index)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Link>
                );
            },
        },
        {
            field: "delete",
            headerName: "Delete",
            width: 300,
            renderCell: (params) => {
                const id = params.row._id;
                return (
                    <Link>
                        <IconButton
                            color="primary"
                            aria-label="edit"
                            onClick={() => handledeleteClick(id)}
                        >
                            <i class="fa-sharp fa-solid fa-trash text-dark"></i>
                        </IconButton>
                    </Link>
                );
            },
        },
    ];

    return (
        <>
            <section>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="bg-white  box">
                                <h4 className="px-3 py-3">Blogs List</h4>
                                <div className="">
                                    <DataGrid
                                        rows={section}
                                        columns={columns}
                                        getRowId={getRowId}
                                        initialState={{
                                            ...section.initialState,
                                            pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default adminLayout(BlogsList);
