import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Form, FormGroup, Modal, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";
import moment from "moment/moment";
import { toast } from "react-toastify";

// it is category List And this View Product

function ViewProduct() {
  const [product, setProduct] = useState([]);

  const [file, setfile] = useState(null)
  const [show, setShow] = useState(false)
  const [vendordata, setvendordata] = useState([])
  const [selectvendor, setselectvendor] = useState("")

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    console.log(token);
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/product`, {
        headers,
      });
      setProduct(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 300 },
    { field: "short_name", headerName: "Short Name", flex: 1 },
    { field: "long_name", headerName: "Long Name", flex: 1 },
    // {
    //   field: "image",
    //   headerName: "image",
    //   flex:1,
    // },
    {
      field: "image",
      headerName: "image",
      flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`https://twinscart.com:8000/${value}`}
            className="img-fluid tableimgmui"
          ></img>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/product_single/${id}`}>
            <IconButton
              style={{ color: "black" }}
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },

    {
      field: "Add",
      headerName: "Add Variant",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/addVariant/${id}`}>
            <i
              className="fa-solid fa-circle-plus text-dark"
              onClick={() => handleEditClick(id)}
            ></i>
          </Link>
        );
      },
    },

    {
      field: "view",
      headerName: "View Variant",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/viewvariant/${id}`}>
            <i
              className="fa-solid fa-eye text-dark"
              onClick={() => handleEditClick(id)}
            ></i>
          </Link>
        );
      },
    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link>
            <IconButton
              color="primary"
              aria-label="edit"
              onClick={() => handledeleteClick(id)}
            >
              <i className="fa-sharp fa-solid fa-trash text-dark"></i>
            </IconButton>
          </Link>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: (params) => {
        // console.log(params.row.created_at)
        return (
          <>
            <h6>{moment(params.row.created_at).format("DD-MM-YYYY")}</h6>
          </>
        )
      },
    },
  ];

  const handledeleteClick = async (id) => {
    let data = JSON.stringify({
      _id: id,
      deleted_at: "D",
    });

    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${BaseUrl}/product`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data.status == "OK") {
          window.location.reload(true);
          // try {
          //   const res = axios.get("https://twinscart.com:8000/section", {
          //     headers,
          //   });

          //   window.location.reload(true);
          // } catch (error) {
          //   console.error("Error fetching category data:", error);
          // }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleFilebaseUpload = (event) => {
    const selectbasefile = event.target.files[0];
    setfile(selectbasefile);
  };

  const handleExcelSubmit = (e) => {
    e.preventDefault()

    let formdata = new FormData()
    formdata.append("vendor", selectvendor);
    formdata.append("excel_file", file);
    axios.post(`${BaseUrl}/upload_base_excel`, formdata, { headers }).then((res) => {

      if (res.data.status == "OK") {
        toast.success("File Upload Successfully")
        setShow(false)
        fetchData()
      }
    }).catch((err) => {
      toast.error("File Not Upload")
    })
  }

  const handlefetchvendor = () => {
    axios.get(`${BaseUrl}/fetch_users_admin?type=Vendor`, { headers }).then((res) => {

      setvendordata(res.data.data)
    })
  }

  useEffect(() => {
    handlefetchvendor()
  }, [])

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <h5 className="px-3 py-3">Product List</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="text-end">
                      <button className="btn bg-primary text-white" onClick={(e) => setShow(true)}>Upload Excel</button>
                    </div>
                  </div>
                </div>
                <div className="">
                  <DataGrid
                    rows={product}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...product.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleExcelSubmit(e)}>
            <div className="text-end">
              <a className="bg-dark text-white btn" href={`${BaseUrl}/uploads/mysampleexcel.xlsx`} download>Download Sample Excel</a>
            </div>
            <FormGroup>
              <label htmlFor="" className="fw-bold">Select Vendor</label>
              <select className="form-control form-select mb-3" value={selectvendor} onChange={(e) => setselectvendor(e.target.value)}>
                <option value="" className="d-none">--Select--</option>
                {vendordata.map((item) => {
                  return (
                    <>
                      <option value={item._id} className="">{item.username}</option>

                    </>
                  )
                })}

              </select>
            </FormGroup>
            <FormGroup>
              <label htmlFor="" className="fw-bold">Excel</label>
              <input type="file" className="form-control" onChange={handleFilebaseUpload} />
            </FormGroup>

            <button className="btn bg-primary text-white mt-2">Submit</button>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
}

export default adminLayout(ViewProduct);
