// import { DataGrid } from "@mui/x-data-grid";
// import React from "react";

// import { Button, IconButton } from "@mui/material";
// import EditIcon from "@mui/icons-material/Edit";
// import { Col, Container, Row } from "react-bootstrap";
// import adminLayout from "../../hoc/adminLayout";

// function BrandList() {
//   const columns = [
//     { field: "id", headerName: "ID", width: 250 },
//     { field: "name", headerName: "Name", width: 250 },
//     { field: "description", headerName: "Description", width: 250 },
//     {
//       field: "quantity",
//       headerName: "Quantity",
//       width: 220,
//       renderCell: (params) => {
//         const { value } = params;
//         return (
//           <span className="bg-primary p-1 w-50 text-center rounded-3 text-white">
//             {value}
//           </span>
//         );
//       },
//     },
//     {
//       field: "edit",
//       headerName: "Edit",
//       width: 100,
//       renderCell: (params) => {
//         return (
//           <IconButton color="primary" aria-label="edit">
//             <EditIcon />
//           </IconButton>
//         );
//       },
//     },
//   ];

//   const rows = [
//     {
//       id: 1,
//       name: "Bass speaker",
//       description: "lfdsjkkkkkkkkkkkkkkkkkkkkkkkkkkk",
//       quantity: "Availble",
//       price: "345",
//       edit: "",
//     },
//     {
//       id: 2,
//       name: "Bass speaker",
//       description: "lfdsjkkkkkkkkkkkkkkkkkkkkkkkkkkk",
//       quantity: "Availble",
//       price: "345",
//       edit: "",
//     },
//     {
//       id: 3,
//       name: "Bass speaker",
//       description: "lfdsjkkkkkkkkkkkkkkkkkkkkkkkkkkk",
//       quantity: "Availble",
//       price: "345",
//       edit: "",
//     },
//     {
//       id: 4,
//       name: "Bass speaker",
//       description: "lfdsjkkkkkkkkkkkkkkkkkkkkkkkkkkk",
//       quantity: "Availble",
//       price: "345",
//       edit: "",
//     },
//   ];

//   return (
//     <>
//       <section>
//         <Container>
//           <Row>
//             <Col md="12">
//               <div className="bg-white  box">
//                 <h4 className="px-3 py-3">Brand List</h4>
//                 <div className="">
//                   <DataGrid
//                     rows={rows}
//                     columns={columns}
//                     initialState={{
//                       pagination: {
//                         paginationModel: { page: 0, pageSize: 5 },
//                       },
//                     }}
//                     pageSizeOptions={[5, 10]}
//                     checkboxSelection
//                   />
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </section>

//     </>
//   );
// }

// export default adminLayout(BrandList);

import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Row } from "react-bootstrap";
import adminLayout from "../../hoc/adminLayout";
import { Link } from "react-router-dom";
import { BaseUrl, token } from "../../utils/Token";

function BrandList() {
  const [brand, setBrand] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/brands`, {
        headers,
      });
      setBrand(response.data.data);
    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 400 },
    { field: "name", headerName: "Name", width: 400 },

    {
      field: "image",
      headerName: "image",
      width: 300,
      renderCell: (params) => {
        const { value } = params;
        return (
          <img
            src={`${BaseUrl}/${value}`}
            className="img-fluid tableimgmui"
          ></img>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => {
        const id = params.row._id;
        return (
          <Link to={`/single_brand/${id}`}>
            <IconButton
              style={{ color: "black" }}
              aria-label="edit"
              onClick={() => handleEditClick(id)}
            >
              <EditIcon />
            </IconButton>
          </Link>
        );
      },
    },
  ];

  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <h4 className="px-3 py-3">Brand List</h4>
                <div className="">
                  <DataGrid
                    rows={brand}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...brand.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(BrandList);
