import React, { useEffect, useState } from 'react'
import adminLayout from '../../hoc/adminLayout'
import axios from 'axios'
import { BaseUrl } from '../../utils/Token'
import Loader from '../../common/Loader'
import { toast } from 'react-toastify'
import { Col } from 'react-bootstrap'
import { DataGrid } from '@mui/x-data-grid'
import { IconButton } from '@mui/material'
import { Link } from 'react-router-dom'

function RegisterAnalyst() {
    const [username, setusername] = useState([])
    const [name, setname] = useState("")
    const [phone, setphone] = useState("")
    const [email, setemail] = useState("")


    const token = localStorage.getItem("token")
    const [data, setdata] = useState([])
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const [open, setopen] = useState(false)
    const handleUserForm = async (e) => {
        e.preventDefault();
        setopen(true)

        const formData = new FormData();

        formData.append("username", name);
        formData.append("type", "Staff");
        formData.append("phone", phone);
        formData.append("email", email);
        formData.append("referal_code", name)



        axios
            .post(`${BaseUrl}/store_user`, formData, {
                headers: headers,
            })
            .then((response) => {
                // Handle successful response
                console.log(response.data);
                if (response.data.status == "OK") {
                    toast.success("data Updated Successfully");
                    setopen(false);
                    setusername("")
                    setphone("")
                    setemail("")
                    fetchData()
                }
            })
            .catch((error) => {
                // Handle error
                console.error(error);
                toast.error("data not Updated");
                setopen(false);
            });
    };

    const getRowId = (row) => row._id

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${BaseUrl}/fetch_users_admin?type=Staff`,
                {
                    headers,
                }
            );
            setdata(response.data.data);
        } catch (error) {
            console.error("Error fetching category data:", error);
        }
    };

    useEffect(() => {
        fetchData()
    }, [])


    const columns = [
        {
            field: "username",
            headerName: "Username",
            flex: 1,
        },
        {
            field: "email",
            headerName: "email",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.email}
                    </>
                )
            }
        },
        {
            field: "phone",
            headerName: "phone",
            flex: 1,
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                const id = params.row._id;
                return (
                    <Link>
                        <IconButton
                            color="primary"
                            aria-label="edit"
                            onClick={() => handledeleteClick(id)}
                        >
                            <i className="fa-sharp fa-solid fa-trash text-dark"></i>
                        </IconButton>
                    </Link>
                );
            },
        },

    ];


    const handledeleteClick = async (id) => {
        let data = JSON.stringify({
            _id: id,
            deleted_at: Date.now(),
        });

        let config = {
            method: "put",
            maxBodyLength: Infinity,
            url: `${BaseUrl}/update_user_admin`,
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            data: data,
        };

        axios
            .request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.status == "OK") {

                    fetchData()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };



    return (
        <>
            <Loader open={open} />
            <section>
                <div className="container">
                    <form onSubmit={handleUserForm} className='bg-white shadow p-3'>
                        <div className="row gy-3">
                            <div className="col-md-12">
                                <div className="addProduct">
                                    <h4 className='py-3'>Ticket Analyst</h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Name</label>
                                <input type="text" value={name} onChange={(e) => setname(e.target.value)} className='form-control' />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Phone</label>
                                <input type="text" value={phone} onChange={(e) => setphone(e.target.value)} className='form-control' />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Email</label>
                                <input type="text" value={email} onChange={(e) => setemail(e.target.value)} className='form-control' />
                            </div>
                            <div className="col-md-4">
                                <button class="btn bg-primary text-white mt-3">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

            {data.length > 0 && <section className='mt-4'>
                <div className="container">
                    <div className="row">
                        <Col md="12">
                            <div className="bg-white  box">
                                <h4 className="px-3 py-3"> List</h4>
                                <div className="">
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}

                                        pageSizeOptions={[5, 10, 25]}
                                    />
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </section>}
        </>
    )
}

export default adminLayout(RegisterAnalyst)