import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Col, Container, Form, Row } from "react-bootstrap";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BaseUrl, token } from "../utils/Token";
import adminLayout from "../hoc/adminLayout";
import { toast } from "react-toastify";
import Loader from "../common/Loader";
import notfound from "../assets/images/notfound.jpg"

function OrderList() {
  const [user, setUser] = useState([]);
  const [orderstatus, setorderstatus] = useState("")
  const [search, setsearch] = useState("")
  const [loading, setloading] = useState(false)
  const navigate = useNavigate()
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const location = useLocation()

  const { unique } = useParams()

  const statuspage = location.pathname == `/orderlist/${unique}`



  useEffect(() => {
    axios.get(`${BaseUrl}/invoice_list`, { headers }).then((res) => {
      let filter = res.data.data.filter((item) => item?.top_level_order_status?.toLowerCase() == unique?.toLowerCase())

      console.log("filter", filter)
      setUser(filter)
      setsearch(unique)
    })
  }, [unique])

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(`${BaseUrl}/order_list`, {
  //       headers,
  //     });
  //     setUser(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching category data:", error);
  //   }
  // };

  function removeBackslashes(inputString) {
    if (typeof inputString !== 'string') {
      return inputString;
    }
    return inputString.replace(/\\/g, '');
  }


  let saledata = ""
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/invoice_list`, {
        headers,
      });
      setUser(response.data.data);

    } catch (error) {
      console.error("Error fetching category data:", error);
    }
  };





  useEffect(() => {
    if (!statuspage) {
      fetchData();
      setsearch("")
    }
  }, [statuspage]);

  const getRowId = (row) => row._id;
  const handleEditClick = (id) => {

  };

  // const columns = [
  //   // { field: "_id", headerName: "ID", width: 300 },
  //   { field: "top_level_order_status", headerName: "Status", width: 350 },
  //   { field: "payable", headerName: "Payable", width: 350 },
  //   // {
  //   //   field: "image",
  //   //   headerName: "image",
  //   //   width: 250,
  //   // },
  //   {
  //     field: "payment_status",
  //     headerName: "payment_status",
  //     width: 300,
  //     // renderCell: (params) => {
  //     //   const { value } = params;
  //     //   return (
  //     //     <img
  //     //       src={`https://twinscart.com:8000/${value}`}
  //     //       className="img-fluid tableimgmui"
  //     //     ></img>
  //     //   );
  //     // },
  //   },
  //   // {
  //   //   field: "edit",
  //   //   headerName: "Edit",
  //   //   width: 100,
  //   //   renderCell: (params) => {
  //   //     const id = params.row._id;
  //   //     return (
  //   //       <Link to={`/product_single/${id}`}>
  //   //         <IconButton
  //   //           style={{ color: "black" }}
  //   //           aria-label="edit"
  //   //           onClick={() => handleEditClick(id)}
  //   //         >
  //   //           <EditIcon />
  //   //         </IconButton>
  //   //       </Link>
  //   //     );
  //   //   },
  //   // },
  // ];



  // const columns = [

  //   // {
  //   //   field: "image",
  //   //   headerName: "Image",
  //   //   flex: 1,
  //   //   renderCell: (params) => {

  //   //     let saledata = removeBackslashes(params.row.sale);
  //   //     saledata = JSON.parse(saledata)
  //   //     let image = saledata[0].product_variant.product.image


  //   //     return <div>
  //   //       <img src={`${BaseUrl}/${image}`} alt="" style={{ height: "50px", width: "50px", borderRadius: "10px", objectFit: "contain" }} />
  //   //     </div>;
  //   //   }
  //   // },

  //   // {
  //   //   field: "short_name",
  //   //   headerName: "Short Name",
  //   //   flex: 1,
  //   //   renderCell: (params) => {

  //   //     let saledata = removeBackslashes(params.row.sale);
  //   //     saledata = JSON.parse(saledata)
  //   //     let name = saledata[0].product_variant.product.short_name


  //   //     return <div>
  //   //       {name}
  //   //     </div>;
  //   //   }
  //   // },

  //   // {
  //   //   field: "price_range",
  //   //   headerName: "Price Range",
  //   //   flex: 1,
  //   //   renderCell: (params) => {

  //   //     let saledata = removeBackslashes(params.row.sale);
  //   //     saledata = JSON.parse(saledata)
  //   //     let name = saledata[0].product_variant.product.price_range


  //   //     return <div>
  //   //       {name}
  //   //     </div>;
  //   //   }
  //   // },



  //   {
  //     field: "order_status",
  //     headerName: "Change Order Status",
  //     flex: 1,
  //     renderCell: (params) => {

  //       return (
  //         <>
  //           <select name="" onChange={(e) => handlechangeorder(e, params.row._id)} value={orderstatus} className="form-control form-select" id="">
  //             <option value="">--Select--</option>
  //             <option value="Pending">Pending</option>
  //             <option value="Accept">Accept</option>
  //             <option value="Packing">Packing</option>
  //             <option value="Shipped">Shipped</option>
  //             <option value="Delivered">Delivered</option>




  //           </select>
  //         </>
  //       )
  //     }
  //   },

  //   {
  //     field: "created_at",
  //     headerName: "Created At",
  //     flex: 1,
  //     renderCell: (params) => {
  //       return (
  //         <h6>{params.row.created_at.substring(0, 10)}</h6>
  //       )
  //     }
  //   },

  //   {
  //     field: "Invoice",
  //     headerName: "Invoice",
  //     flex: 1,
  //     renderCell: (params) => {
  //       return (
  //         <button onClick={(e) => handledetail(e, params)} className="btn"><i class="fa-solid fa-circle-info"></i></button>
  //       )
  //     }
  //   },





  // ];





  const handledetail = (e, params) => {
    e.preventDefault()
    navigate('/productdetail', { state: params })
    // console.log(params)
  }


  const handlechangeorder = (e, id, name) => {
    let value = e.target.value;
    let requestData = {
      _id: id,
      [name]: value
    };

    axios.put(`${BaseUrl}/invoice`, requestData, { headers })
      .then((res) => {
        if (res.data.status == "OK") {
          toast.success("Date Updated Successfully")
          fetchData()
        }
      })
      .catch((error) => {
        console.error('Error updating order:', error);
        toast.error("Data Not Updated")
      });
  };

  const handleSearch = (e) => {
    e.preventDefault()

    let filterdata = user?.filter((item) => {
      const usernameMatch = item?.vendor?.username?.toLowerCase() === search?.toLowerCase();
      const phoneMatch = item?.user?.phone === search;
      const vendorphone = item?.vendor?.phone == search
      const isordersettle = item?.is_order_settled?.toLowerCase() == search?.toLowerCase();
      const top_level_order_status = item?.top_level_order_status?.toLowerCase() == search?.toLowerCase();


      return usernameMatch || phoneMatch || vendorphone || isordersettle || top_level_order_status;

    });

    console.log(filterdata);
    setUser(filterdata)
  };

  useEffect(() => {
    if (search?.length == 0 && !statuspage) {
      fetchData()
      // console.log("hello reset")
    }
  }, [search])




  return (
    <>
      {loading && <Loader />}
      <section>
        <Container>
          <Row>
            <Col md="12">
              <div className="bg-white  box">
                <div className="" style={{ borderBottom: " 1px solid rgba(0, 0, 0, 0.12)" }}>
                  <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                      <h5 className="px-3 py-3">Invoice List</h5>
                    </div>
                    <div className="col-md-4">
                      <div className="me-3">
                        <Form onSubmit={(e) => handleSearch(e)}>
                          <div className="input-group searchbox rounded-3">
                            <input type="search" className="form-control" required value={search} onChange={(e) => setsearch(e.target.value)} placeholder="Search by Name , Phone , Status" />
                            <button className="btn bg-primary text-white"><span className="p-2"><i class="fa-solid fa-magnifying-glass"></i></span></button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>

                </div>
                {/* <div className="">
                  <DataGrid
                    rows={reversedRows}
                    columns={columns}
                    getRowId={getRowId}
                    initialState={{
                      ...user.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  />
                </div> */}

                {user.length > 0 ?
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead >
                        <tr>
                          <th>User </th>
                          <th>Vendor</th>
                          <th>Commission</th>
                          <th>Items</th>
                          <th>Payable Order</th>
                          <th>Shipping Charge</th>
                          <th>Change Order Status</th>
                          <th>Change Payout Status</th>
                          <th>Invoice Detail</th>
                        </tr>
                      </thead>
                      <tbody>
                        {user?.reverse().map((item) => {
                          let saledata = removeBackslashes(item?.sale);
                          saledata = JSON.parse(saledata)
                          let lenghtdata = saledata.length

                          return (
                            <>
                              <tr>
                                <td>
                                  <div>
                                    <h6>Phone : <span>{item?.user?.phone}</span></h6>
                                    {item.user?.email && <h6>Email : <span>{item?.user?.email}</span></h6>}

                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <h6>Name : <span>{item.vendor.username}</span></h6>
                                    <h6>Phone : <span>{item.vendor.phone}</span></h6>
                                  </div>
                                </td>
                                <td>
                                  <h6>{item.commission}</h6>
                                </td>
                                <td>
                                  <h6>{lenghtdata}</h6>
                                </td>
                                <td>
                                  <h6>{item.payable_order_value}</h6>
                                </td>
                                <td>
                                  <h6>{item.shipping_charge}</h6>
                                </td>
                                <td>
                                  <select name="" style={{ fontSize: '12px' }} value={item.top_level_order_status} onChange={(e) => handlechangeorder(e, item._id, "top_level_order_status")} className="form-control form-select" id="">
                                    <option value="">--Select--</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Shipped">Shipped</option>
                                    <option value="Out For Delivery">Out For Delivery</option>
                                    <option value="Delivered">Delivered</option>
                                    <option value="Return Request">Return Request</option>
                                    <option value="Returned">Returned</option>
                                    <option value="Cancelled">Cancelled</option>
                                  </select>


                                </td>
                                <td>
                                  <select name="" style={{ fontSize: '12px' }} onChange={(e) => handlechangeorder(e, item._id, "is_order_settled")} value={item.is_order_settled} className="form-control form-select" id="">
                                    <option value="">--Select--</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Processing">Processing</option>
                                    <option value="Settled">Settled</option>
                                  </select>

                                </td>
                                <td>
                                  <button onClick={(e) => handledetail(e, item)} className="btn"><i class="fa-solid fa-circle-info"></i></button>
                                </td>
                              </tr>
                            </>
                          )
                        })}

                      </tbody>
                    </table>
                  </div> : <>
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-4">
                        <div className="text-center ">
                          <img src={notfound} className="img-fluid" alt="" />

                        </div>
                      </div>
                    </div>
                  </>}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default adminLayout(OrderList);
